@import 'normalize';
@import 'colors';

body {
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  color: #355667;
}

h1, h2, h3, h4 {
  font-weight: normal;
  font-family: 'Pinyon Script', cursive;
}

h1 {
  font-size: 5em;
  margin: 0;
}

h2 {
  font-size: 3em;
  margin: 0.25em;
}

h3 {
  font-size: 2.5em;
  margin: 0.25em;
}

p {
  max-width: 36em;
  margin: 1em auto;
  line-height: 2em;

  &.large {
    font-size: 1.5em;
  }

  &.caps {
    text-transform: uppercase;
  }
}

a {
  color: $plum;
  text-decoration: none;
}

.container {
  margin: 0 auto;
  text-align: center;
}

.navigation-wrapper {
  background-color: #355667;

  .navigation-content:last-child {
    text-align: right;
  }
}

.navigation {
  max-width: 1024px;
  margin: 0 auto;
  text-align: left;
  padding: 0.5em;
  color: #fff;
  display: flex;
  align-items: center;

  a {
    font-size: 0.75em;
    text-decoration: none;
    margin-right: 1em;
    text-transform: uppercase;
    font-weight: bold;
    color: #f0f0f0;
    transition: color 0.25s;

    &:hover {
      color: #fff;
    }
  }
}

.nav-header {
  text-align: left;
}

.navigation-content {
  flex: 1;
}

.section-divider {
  margin: 10em 0;
}

.hero-container {
  background: url('/images/tree.jpg') no-repeat center top;
  background-size: cover;

  .hero-content {
    padding: 18em 0;
  }

  h1,p {
    color: #fff;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
}

img.hero {
  max-width: 480px;
  max-height: 640px;
  width: 100%;
  filter: grayscale(70%);
  background-color: #fff;
  margin: 1em;
  transition: filter 0.25s;

  &:hover {
    filter: grayscale(50%);
  }
}

img.feature {
  max-width: 240px;
  max-height: 320px;
  width: 100%;
  filter: grayscale(70%);
  background-color: #fff;
  transition: filter 0.25s;

  &:hover {
    filter: grayscale(50%);
  }
}

.footer {
  height: 8em;
  background-color: #814d71;
}

@media only screen and (max-device-width: 480px) {
  h1 {
    font-size: 3em;
  }

  .hero-container h1 {
    font-size: 2.5em;
  }

  .hero-container p {
    font-size: 1em;
  }

  img.hero {
    margin: 1em 0;
  }

  .navigation-wrapper a {
    display: block;
    margin: 1.25em 0;
  }
}
