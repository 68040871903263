// cold pallette
$tan: #c5afa2;
$blue: #557687;
$purple: #68324a;
$pink: #cfa5a6;

// warm pallette
$gold: #ca8f4d;
$pink: #a76e81;
$slate: #888a87;
$plum: #814d71;
